import React from 'react';
import styled from '@emotion/styled';

interface PricingSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    sectionTitle?: React.ReactNode;
}

const Pricing: React.FC<PricingSectionProps> = ({
  sectionTitle,
  ...props
}) => {
  return (
    <StyledCardsSection {...props}>
      <h2>{sectionTitle}</h2>
      <div className="cards-grid">
           <div key="card_pricing_1" className="card">
            <h6 className="card__title">Listing Assistant</h6>
            <p>Flat Rate</p>
            <h2 className="card__title">$99</h2>
            <ul className='card-ul'>
               <li>Dedicated phone & text line 7 days a week</li>  
               <li>MLS Listing Creation & management</li>
               <li>Keep file compliant</li>
               <li>Easy vendor management (Photographer, Surveyor, etc)</li>
               <li>Listing photo management</li>
               <li>Showings management</li>
            </ul>
          </div>
          <div key="card_pricing_2" className="card">
            <h6 className="card__title">Purchase Assistant</h6>
            <p>Flat Rate</p>
            <h2 className="card__title">$99</h2>
            <ul className='card-ul'>
               <li>Dedicated phone & text line 7 days a week</li>  
               <li>Create & Sign offers</li>
               <li>Gather required forms</li>
               <li>Coordinate signing of required forms & disclosures</li>
               <li>Keep file compliant</li>
            </ul>
          </div>
          <div key="card_pricing_3" className="card">
            <h6 className="card__title">Transaction Assistant Pro</h6>
            <p>Flat Rate for Coordination</p>
            <h2 className="card__title">$299</h2>
            <ul className='card-ul'>
               <li>MLS Management Contract to Close</li>
               <li>Full Transaction Coordination</li>
               <li>Review and store all paperwork</li>
               <li>Vendor management and communication</li>
               <li>Coordinate inspections</li>
               <li>Manage surveys, escrow and title</li>
               <li>100% communication and follow-ups</li>
               <li>Create broker commission statement</li>
               <li>Pay us only when your file closes</li>
            </ul>
          </div>
      </div>
      <div className='totalPackage'>
        <div>
        <h6 className="card__title">Transaction Assistant Platinum</h6>
            <p>Flat Rate</p>
            <h2 className="card__title">$449</h2>
            <p>Get someone to cover you from pre-closing to closing. A hands on approach with queue priority and a dedicated team handling all paperword, coordination and communications, From A-Z.</p>
            <p><i>Fee includes all services listed above in other packages *</i></p>
        </div>
            
      </div>
    </StyledCardsSection>
  );
};

export default Pricing;

const StyledCardsSection = styled.section`
  .description {
    max-width: 580px;
    display: inline-block;
  }
  .totalPackage{-moz-box-shadow: 10px 10px 5px #efefef;
    -webkit-box-shadow: 10px 10px 5px #efefef;
    box-shadow: 10px 10px 5px #efefef;
    padding: 32px 20px;
    margin-top: 30px;}
  .totalPackage > div{max-width: 768px;
    margin: auto;}
  .cards-grid {
    display: grid;
    width: 100%;
    margin: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    justify-items: center;
    margin-top: 60px;

    .card {
      width: 100%;
      /* height: 330px; */
      border: 1px solid ${props => props.theme.colors.borderColor};
      padding: 32px 20px;
      -moz-box-shadow: 10px 10px 5px #efefef;
    -webkit-box-shadow: 10px 10px 5px #efefef;
    box-shadow: 10px 10px 5px #efefef;
      ul{
        list-style:none;
      }
      li {
        padding: 5px 0px;
      }

      h2{    margin: 0;
        font-size: 46px;}

      &__title {
        margin: 32px 0 -16px;
        font-size: 18px;
        font-weight: 600;
      }

      &__text {
        margin: 32px 0 0;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .cards-grid {
      margin-top: 0;
      grid-template-columns: 1fr;
      grid-gap: 24px;

      .card {
        padding: 20px 12px;
      }
    }
  }
`;
