import React from 'react';
import styled from '@emotion/styled';

interface BigPictureSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  header: string;
  subheader: string;
  text: string;
}

const TwoSection: React.FC<BigPictureSectionProps> = ({
    header,
    subheader,
  text,
  className
}) => {
  return (
    <StyledTwoSection className={className}>
        <div className="big-pic-section__content twoheader">{header}<br></br>{subheader}</div>
      <div className="big-pic-section__content">{text}</div> 
    </StyledTwoSection>
  );
};

export default TwoSection;

const StyledTwoSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  text-align: unset !important;

  .twoheader{    font-weight: bold;
    font-size: 37px;
    line-height: 48px;}

  &.left {
    & > .big-pic-section__content {
      grid-column: 2;
    }
    & > .big-pic-section__image {
      grid-column: 1;
      grid-row: 1;
    }
  }

  .button {
    margin-top: 24px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    grid-gap: 24px 0;
    &.right,
    &.left {
      & > .big-pic-section__content {
        order: 1;
        grid-column: unset;
      }
      & > .big-pic-section__image {
        order: 0;
        grid-column: unset;
      }
    }
    .button {
      margin: 24px 0;
    }
  }
`;
