import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { ContactForm } from 'components/forms';
import { ReactComponent as PinSVG } from 'assets/icons/pin.svg';

const ContactSection = () => {
  const { t } = useTranslation();
  return (
    <StyledContactSection className="contact-section">
      <div className="contact-section__block">
        <h3>{t('contact.title')}</h3>
        <div className="contact-section__contact">
          <a href={`mailto:${t('contact.email')}`}>{t('contact.email')}</a>
        </div>
        <div className="contact-section__contact">
          <a href={`tel:${t('contact.phone')}`}>{t('contact.phone')}</a>
        </div>
        {/*<p className="customer-service">{t('contact.customer_service')}</p>*/}
        <h4>{t('contact.location')}</h4>
        <div className="location">
          <PinSVG />
          {t('contact.address')}
        </div>
      </div>
      <div className="contact-section__block">
        <StyledContactForm formTitle={t('contact_form.title')} extended />
      </div>
    </StyledContactSection>
  );
};

const StyledContactSection = styled.section`
  padding: 16px;
  max-width: 1057px;
  margin: 0 auto 60px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 496px 442px;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  position: relative;

  h3,
  h4 {
    font-weight: 400;
    margin: 0 0 20px;
    text-align: left;
  }

  h3 {
    font-size: 36px;
    line-height: 54px;
  }

  h4 {
    font-size: 28px;
    line-height: 42px;
  }

  .contact-section {
    &__block {
      max-width: 434px;
    }
    &__icon {
      margin: 0 0 20px;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 32px;
    }
    &__contact {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 16px;
      border: 1px solid #d9d9d9;
      border-radius: 100px;
      height: 48px;
      max-width: 347px;

      a {
        font-size: 18px;
        line-height: 24px;
        color: #434343;
        text-decoration: none;
      }
    }
  }

  .customer-service {
    margin: 0 0 24px;
    max-width: 347px;
    font-size: 16px;
    line-height: 24px;
    color: #7f7f7f;
    text-align: center;
  }

  .location {
    display: flex;
    text-align: left;

    svg {
      margin-right: 8px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    display: block;
    padding: 0 16px 28px 16px;
	 margin: 0 auto 32px;

    .contact-section {
      &__title {
        max-width: 285px;
      }
      &__description {
        margin: 0 0 24px;
      }
      &__block {
        max-width: 100%;
      }
      &__contact {
        max-width: 100%;
      }
    }
    .customer-service {
      max-width: 100%;
    }
    .location {
      margin: 0 0 32px;
    }
  }
`;

const StyledContactForm = styled(ContactForm)`
  width: 100%;
  .contact-form {
    &__form {
      margin: 0;
    }
    &__title {
      margin-right: auto;
      text-align: left;
      font-weight: 500;
    }
    &__input .input__component,
    &__textarea .input__component {
      font-size: 16px;
      line-height: 24px;
    }
    &__input .input__component {
      height: 44px;
    }
    &__button {
      width: 100%;
      height: 40px;
      margin: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .contact-form {
      &__textarea {
        margin: 0 0 24px;
      }
      &__input {
        max-width: 100% !important;
      }
    }
  }
`;

export default ContactSection;
