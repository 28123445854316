import React from 'react';
import styled from '@emotion/styled';

interface Faqs extends React.HTMLAttributes<HTMLDivElement> {
    sectionTitle?: React.ReactNode;
  className?: string;
  cards: { key: string | number;text?: string;question: string }[];
}

const Faqs: React.FC<Faqs> = ({
    sectionTitle,
    className,
    cards = [],
}) => {
  return (
    
    <StyledFaqs className={className}>
        <h2>{sectionTitle}</h2>
        {cards.map(card => (
          <div key={card.key} className="card">
            {card.question && <h6 className="faq__question">{card.question}</h6>}
            <p className="faq__text">{card.text}</p>
          </div>
        ))}
    </StyledFaqs>
  );
};

export default Faqs;

const StyledFaqs = styled.section`
  text-align:center;
  .card{
    max-width: 768px;
    display: block;
    width: 100%;
    float: none;
    margin: auto;
  }
`;
