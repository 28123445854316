import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

interface CardsSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  sectionTitle?: React.ReactNode;
  description?: React.ReactNode;
  cards: { key: string | number; image?: any; title?: React.ReactNode; text?: React.ReactNode }[];
}

const CardsSection: React.FC<CardsSectionProps> = ({
  sectionTitle,
  description,
  cards = [],
  ...props
}) => {
  return (
    <StyledCardsSection {...props}>
      {sectionTitle && <h2>{sectionTitle}</h2>}
      <p className="description">{description}</p>
      <div className="cards-grid">
        {cards.map(card => (
          <div key={card.key} className="card">
            {card.image && <Image className="card__image" fluid={card.image} />}
            {card.title && <h6 className="card__title">{card.title}</h6>}
            <p className="card__text">{card.text}</p>
          </div>
        ))}
      </div>
    </StyledCardsSection>
  );
};

export default CardsSection;

const StyledCardsSection = styled.section`
  .description {
    max-width: 580px;
    display: inline-block;
  }
  .cards-grid {
    display: grid;
    width: 100%;
    margin: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    justify-items: center;

    .card {
      width: 100%;
      /* height: 330px; */
      padding: 32px 20px;

      &__image {
        max-width: 354px;
        margin: 0 auto;
      }

      &__title {
        margin: 32px 0 -16px;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      &__text {
        margin: 32px 0 0;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .cards-grid {
      margin-top: 0;
      grid-template-columns: 1fr;
      grid-gap: 24px;

      .card {
        padding: 20px 12px;
      }
    }
  }
`;
