import React from 'react';
import styled from '@emotion/styled';
import Image, { GatsbyImageProps } from 'gatsby-image';
import clsx from 'clsx';

interface BigPictureSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  imagePosition?: 'left' | 'right';
  image: GatsbyImageProps;
  className?: string;
}

const BigPictureSection: React.FC<BigPictureSectionProps> = ({
  image,
  imagePosition = 'right',
  children,
  className
}) => {
  return (
    <StyledBigPictureSection className={clsx('big-pic-section', imagePosition, className)}>
      <div className="big-pic-section__content">{children}</div>
      <Image className="big-pic-section__image" {...image} />
    </StyledBigPictureSection>
  );
};

export default BigPictureSection;

const StyledBigPictureSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  text-align: unset !important;
  margin-top:0 !important;

  &.left {
    & > .big-pic-section__content {
      grid-column: 2;
    }
    & > .big-pic-section__image {
      grid-column: 1;
      grid-row: 1;
    }
  }

  .button {
    margin-top: 24px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    grid-gap: 24px 0;
    &.right,
    &.left {
      & > .big-pic-section__content {
        order: 1;
        grid-column: unset;
      }
      & > .big-pic-section__image {
        order: 0;
        grid-column: unset;
      }
    }
    .button {
      margin: 24px 0;
    }
  }
`;
